#main-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#signin-row, #signup-row, #contact-row {
    margin-bottom: 3px; /* This simulates 'gy-2' for vertical spacing */
    display: flex;
    justify-content: center;
    width: 100%;
}

#signin-col, #signup-col, #contact-col {
    width: 41.666667%; /* Approximation of 'col-md-5' for medium devices */
}


button {
    padding: 15px 30px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
    min-width: fit-content;
}

#sign-in {
    background-color: var(--main-blue);
    color: white;
    border: 0px;
    border-radius: 30px;
    width: 100%;
}

#sign-up, #contact {
    background-color: white;
    border-radius: 30px;
    width: 100%;
}

#get-started {
    margin-block-start: 20vh;
    margin-block-end: 0px;
    margin-right: 10vw;
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Responsive behavior for medium devices */
@media (max-width: 768px) {
    #signinCol, #signupCol, #contactCol {
        width: 100%; /* Full width on smaller screens */
    }
}