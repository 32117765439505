@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&family=Nunito:wght@400;600&family=Poppins:wght@400;600&family=Questrial&family=Quicksand:wght@400;600&family=Raleway:wght@400;600&display=swap');

* {
    font-family: 'Questrial';
}

:root {
    --main-blue: #5774f0;
    --light-gray: #f1f3f4;
    --light-green: #d3ffecff;
    --dark-yellow: #d1cd00;
    --light-green-hover: #f0fff9ff;
    --sign-up-outline: #36498a;
    --dark-blue-button: #2a3041ff;
    --light-blue-button: #cbd1ecff;
    --dark-blue-button-hover: #5d6270ff;
    --light-blue-button-hover: #f3f5fcff;
    --main-blue-hover: #7b92f5ff;
    --delete-consultation-hover: #f4e8ffff;
    --red-button: #fa4639;
    --red-button-hover: #de8b85;
  }