.note {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.row {
    display: flex;
}

#space-between {
    padding-top: 25px;
    justify-content: space-between;
}

#space-between-no-pad {
    justify-content: space-between;
}

.transcript-box {
    flex: 5.5;
    background-color: rgb(255, 255, 255, 50%);
    white-space: pre-wrap;
    overflow-y: scroll;
    overflow-x: scroll;
    height: 40vh;
    display: flex;
    flex-direction: column;
}

#permission { 
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

#note-generated-smUp {
    border: 2px solid #5773f0;
    background-color: #f7f9feff;
    margin-left: 0px;
    width: 90%;
}

#scrollable {
    overflow-y: auto;
}

.bigger-button {
    margin-top: 10px;
    width: 100%;
    border-radius: 6px;
    text-align: start;
    margin-right: 5px;
}

.visit-button {
    padding: 10px;
    border-radius: 5px;
    align-self: center;
    text-align: start;
    margin-top: 0px;
    width: 242px;
}

#start-button {
    background-color: black;
    border: 2px solid black;
    color: white;
    margin-left: 65px;
}

#start-button-smUp {
    background-color: black;
    border: 1px solid black;
    color: white;
    width: 190px;
}

#stop-button-smUp {
    background-color: white;
    border: 1px solid black;
    color: black;
    width: 190px;
}

#buttons-smUp {
    margin-left: 35px;
    align-self: center;
}

#complete-button-smUp {
    background-color: var(--main-blue);
    border: 1px solid var(--main-blue);
    color: white;
    width: 190px;
}

#summary-title {
    margin-right: 3vw;
}

#copy-button {
    background-color: var(--main-blue);
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    width: fit-content;
    height: fit-content;
    padding: 10px;
}

#copy-button:active {
    background-color: var(--light-blue-button);
}

#email-button {
    padding: 10px;
}

#transcript-button {
    padding: 10px;
}

#edit-note-button {
    margin-left: 0px;
    margin-right: 15px;
    border: 1px solid #cdd7e1;
    background-color: white;
    color: black;
}

#edit-note-button:active {
    background-color: #dde7ee;
}

#stop-button-no-margin{
    margin-left: 0px;
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
    color: black;
}

.smaller-button {
    width: 50%;
    margin-left: 5px;
    margin-top: 10px;
    border-radius: 6px;
    color: white;
    border: 1px solid var(--main-blue);
    background-color: var(--main-blue);
    text-align: start;
}

#set-padding-buttons {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    flex: 1;
}

h2{
    font-size: 25px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-left: 23px;
}


#pen-edit {
    display: block;
    margin-left: 5px;
    height: 27px;
    cursor: pointer;
    filter: invert(50%);
}

#visit-window {
    margin-top: 2vh;
    margin-left: 1vw;
    height: 96vh;
    overflow-y: hidden;
}

#visit-window-smUp {
    padding-top: 1vw;
    margin-left: 25px;
    height: 90vh;
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
}

#align-together {
    align-items: center;
    display: flex;
    margin-left: 8px;
}

#ending-elements {
    margin-right: 2vw;
    line-height: 1.5rem;
    margin-left: 32px;
}

#space-evenly {
    justify-content: space-evenly;
    padding-left: 7px;
    padding-right: 36px;
    border-bottom: 3px solid var(--light-gray);
}

#language {
    padding: 3px;
    height: min-content;
    border: 2px solid var(--light-gray);
    border-radius: 5px;
    visibility: hidden;
}

#note {
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    height: min-content;
    border: 2px solid var(--light-gray);
    border-radius: 5px;
    align-self: center;
}

#note-smUp {
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: min-content;
    border: 2px solid var(--light-gray);
    border-radius: 5px;
    align-self: center;
}

#timer-style {
    padding: 15px;
    height: min-content;
    border-bottom: 2px solid var(--light-gray);
    border-radius: 5px;
    display: flex;
}

.edit-transcript {
    width: 28px;
    cursor: pointer;
}

.edit-transcript:hover {
    opacity: 0.7;
}


#space-children {
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding-left: 25px;
    padding-right: 0px;
    padding-top: 2px;
    padding-bottom: 10px;
}

#set-padding-transcript {
    padding: 20px;
    padding-bottom: 0px;
    flex: 1;
}

#warning-text {
    margin-top: 5px;
    border-top: 2px solid var(--light-gray);
    padding: 10px;
    font-size: 15px;
}

p {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
    display: flex;
    z-index: 1000;
  }

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 3vh;
    height: 3vh;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dropzone {
    border: 2px dashed var(--main-blue);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.dropzone.dragging {
    background-color: #f8f8f8;
}

.upload-popup {
    padding: 30px;
    background-color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
    border: 1px solid #f3f3f3;
}

.remove-file {
    color: var(--dark-blue-button);
    cursor: pointer;
    font-size: 15px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

.remove-file:hover {
    opacity: 0.7;
}

#upload-button {
    border: 1px solid var(--light-blue-button);
    background-color: var(--light-blue-button);
    color: #304186;
}

.remove-popup {
    color: var(--dark-blue-button);
    cursor: pointer;
    font-size: 20px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

#space-note {
    width: 100%
}
#space-note-upload {
    width: 100%;
    margin-right: 23px;
}

#big-header {
    font-weight: 800;
    font-size: 15pt;
}

#small-header {
    font-weight: 600;
    font-size: 13pt;
}

#paragraph {
    font-weight: 400;
    font-size: 11pt;
}

#styling-sub {
    padding-left: 10px;
}

#padding-span {
    padding-left: 10px;
    padding-right: 10px;
}

#save-button {
    background-color: var(--light-green);
    border: 1px solid black;
    color: black;
}

#waveform-bounds {
    align-content: start;
    height: 20vh;
    width: 600px;
}

#generate-options {
    margin-left: 32px;
}

#generate-options-smUp {
    margin-top: 10px;
}

.placeholder {
    margin-left: 70px;
}

.placeholder-smUp {
    height: 40px;
}

#center-all-elements {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center align */
    align-items: center;
}

.recording-dot {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
    align-self: center;
    animation: blink 1s infinite; /* Define a blinking animation */
  }

  #fit-content {
    flex: 4.5;
    margin-left: 20px;
    min-height: fit-content;
    width: 96.5%;
    margin-bottom: 5vh;
    height: 90%;
  }

.transcript-box-smUp {
    margin-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    flex: 1;
    border: 2px solid var(--light-gray);
    border-radius: 10px;
    white-space: pre-wrap;
    overflow-y: hidden;
    overflow-x: scroll;
    height: 40vh;
    display: flex;
    flex-direction: column;
}
#note-title-smUp {
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 3px;
}
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1500px) {
    #ending-elements {
        margin-right: 4vw;
    }
    #generate-options { 
        width: 95%;
    }
    #fit-content { 
        width: 94%;
    }
    #visit-window {
        height: 110vh;
    }
}

@media (max-width: 1300px) { 
    #ending-elements {
        padding-top: 10px;
        margin-right: 6vw;
    }
    #generate-options { 
        width: 92%;
    }
    #fit-content { 
        width: 91.5%;
    }
}

@media (max-width: 600px) {
    #ending-elements {
        margin-right: 5vw;
    }
    #fit-content { 
        width: 70%;
    }
    #waveform-bounds {
        width: 300px;
    }
}
