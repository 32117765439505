.body-part {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .highlighted {
    background-color: rgba(255, 0, 0, 0.5);
    color: white;
  }
  
  .container {
    display: flex;
    align-items: center;
  }
  
  .body-model {
    position: relative;
    height: 600px;
    width: 250px;
  }
  
  .front-view,
  .back-view {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .front-view {
    background-image: url('../../public/assets/frontbody.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .back-view {
    background-image: url('../../public/assets/backbody.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .body-part {
    position: absolute;
    cursor: pointer;
    border: 1px solid transparent;
  }
  
  .highlighted {
    border: 2px solid red;
  }
  
  /* Front View Positions */
  #Head {
    top: 10%; /* Adjust based on image positioning */
    left: 40%;
    width: 17%; /* Was 30%, now 15% */
    height: 9%;
    border-radius: 50%;
  }
  
  #Neck {
    top: 20%;
    left: 42%;
    width: 13%; /* Was 26%, now 13% */
    height: 6%;
    border-radius: 50%;
  }
  
  #RightChest {
    top: 26%;
    left: 32%;
    width: 16%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 40%;
  }
  
  #LeftChest {
    top: 26%;
    left: 49%;
    width: 16%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 40%;
  }
  
  #Stomach {
    top: 35%;
    left: 36%;
    width: 25%; /* Was 24%, now 12% */
    height: 14%;
    border-radius: 10%;
  }

  #RightShoulder {
    top: 22%;
    left: 21%;
    width: 20%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }
  
  #UpperRightArm {
    top: 26%;
    left: 20%;
    width: 10%; /* Was 20%, now 10% */
    height: 10%;
    border-radius: 50%;
  }

  #RightElbow {
    top: 36%;
    left: 20%;
    width: 10%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }
  
  #LowerRightArm {
    top: 40%;
    left: 15%;
    width: 10%; /* Was 20%, now 10% */
    height: 10%;
    border-radius: 50%;
  }

  #RightHand {
    top: 50%;
    left: 6%;
    width: 14%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }

  #LeftShoulder {
    top: 22%;
    left: 56%;
    width: 20%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }
  
  #UpperLeftArm {
    top: 26%;
    left: 66%;
    width: 10%; /* Was 20%, now 10% */
    height: 10%;
    border-radius: 50%;
  }

  #LeftElbow {
    top: 36%;
    left: 66%;
    width: 10%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }
  
  #LowerLeftArm {
    top: 40%;
    left: 72.5%;
    width: 10%; /* Was 20%, now 10% */
    height: 10%;
    border-radius: 50%;
  }

  #LeftHand {
    top: 50%;
    left: 78%;
    width: 14%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }
  
  #UpperRightLeg {
    top: 52%;
    left: 34%;
    width: 13%; /* Was 20%, now 10% */
    height: 14%;
    border-radius: 50%;
  }

  #RightKnee {
    top: 67%;
    left: 36%;
    width: 12%; /* Was 20%, now 10% */
    height: 5%;
    border-radius: 50%;
  }
  
  #LowerRightLeg {
    top: 73%;
    left: 37%;
    width: 10%; /* Was 20%, now 10% */
    height: 12%;
    border-radius: 50%;
  }

  #RightFoot {
    top: 85%;
    left: 36%;
    width: 12%; /* Was 20%, now 10% */
    height: 5%;
    border-radius: 50%;
  }
  
  #UpperLeftLeg {
    top: 52%;
    left: 50%;
    width: 13%; /* Was 20%, now 10% */
    height: 14%;
    border-radius: 50%;
  }

  #LeftKnee {
    top: 67%;
    left: 50%;
    width: 12%; /* Was 20%, now 10% */
    height: 5%;
    border-radius: 50%;
  }
  
  #LowerLeftLeg {
    top: 73%;
    left: 50%;
    width: 10%; /* Was 20%, now 10% */
    height: 12%;
    border-radius: 50%;
  }

  #LeftFoot {
    top: 85%;
    left: 50%;
    width: 12%; /* Was 20%, now 10% */
    height: 5%;
    border-radius: 50%;
  }
  
  /* Back View Positions */
  #BackHead {
    top: 11%; /* Adjust based on image positioning */
    left: 42%;
    width: 18%; /* Was 30%, now 15% */
    height: 8%;
    border-radius: 50%;
  }
  
  #BackNeck {
    top: 20%;
    left: 45%;
    width: 13%; /* Was 26%, now 13% */
    height: 6%;
    border-radius: 50%;
  }

  #LeftTrap {
    top: 23%;
    left: 26%;
    width: 18%; /* Was 26%, now 13% */
    height: 3%;
    border-radius: 50%;
  }
  
  #BackLeftShoulderBlade {
    top: 26%;
    left: 36%;
    width: 15%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 40%;
  }

  #RightTrap {
    top: 23%;
    left: 59%;
    width: 18%; /* Was 26%, now 13% */
    height: 3%;
    border-radius: 50%;
  }
  
  #BackRightShoulderBlade {
    top: 26%;
    left: 54%;
    width: 15%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 40%;
  }

  #LowerBack {
    top: 37%;
    left: 39%;
    width: 25%; /* Was 24%, now 12% */
    height: 10%;
    border-radius: 10%;
  }

  #Tailbone {
    top: 48%;
    left: 37%;
    width: 30%; /* Was 24%, now 12% */
    height: 6%;
    border-radius: 10%;
  }
  
  #BackUpperLeftArm {
    top: 28%;
    left: 24%;
    width: 12%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }
  
  #BackLowerLeftArm {
    top: 41%;
    left: 18%;
    width: 10%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }
  
  #BackUpperRightArm {
    top: 28%;
    left: 70%;
    width: 12%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }
  
  #BackLowerRightArm {
    top: 41%;
    left: 75%;
    width: 10%; /* Was 20%, now 10% */
    height: 8%;
    border-radius: 50%;
  }
  
  #BackUpperLeftLeg {
    top: 52%;
    left: 38%;
    width: 11%; /* Was 20%, now 10% */
    height: 16%;
    border-radius: 50%;
  }
  
  #BackLowerLeftLeg {
    top: 72%;
    left: 39%;
    width: 10%; /* Was 20%, now 10% */
    height: 16%;
    border-radius: 50%;
  }

  #LeftHeel {
    top: 85%;
    left: 43%;
    width: 8%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }
  
  #BackUpperRightLeg {
    top: 52%;
    left: 55.5%;
    width: 10%; /* Was 20%, now 10% */
    height: 16%;
    border-radius: 50%;
  }
  
  #BackLowerRightLeg {
    top: 72%;
    left: 55.5%;
    width: 10%; /* Was 20%, now 10% */
    height: 16%;
    border-radius: 50%;
  }

  #RightHeel {
    top: 85%;
    left: 54%;
    width: 8%; /* Was 20%, now 10% */
    height: 4%;
    border-radius: 50%;
  }