


#row { 
    display: flex;
}

#center-title {
    text-align: center;
}

#take-screen {
    display: flex;
    flex-direction: column;
    height: 75vh;
    justify-content: center;
}

#audio {
    width: 800px;
    height: 175px;
}


@media (max-width: 600px) {
    #audio {
        width: 300px;
    }
}



